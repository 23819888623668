<template>
  <div class="home">
    <div class="container">
      <div class="header">
        <h1>Yun—v1.0</h1>
      </div>
      
      <nav class="nav-menu">
        <router-link to="/" class="nav-item" :class="{ active: $route.path === '/' }">Home</router-link>
        <router-link to="/feed" class="nav-item" :class="{ active: $route.path === '/feed' }">Feed</router-link>
        <router-link to="/system-status" class="nav-item" :class="{ active: $route.path === '/system-status' }">System Status</router-link>
      </nav>

      <div class="content">
        <!-- <img src="https://static.wixstatic.com/media/0fa9ab_5f1bf038757b4795999d938358ac3351~mv2.png/v1/fill/w_600,h_900,al_c,q_90,usm_0.66_1.00_0.01,enc_avif,quality_auto/why_grumpy_Realistic_photograph_of_a_Japanese_high_school_girl__b1327aae-ac44-43b9-825e-ac.png" alt="Akane" class="main-image"> -->
        <img src="../assets/15421733739829_.pic.jpg" alt="Yun" class="main-image">
        <h2 class="title">Yun</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.home {
  min-height: 100vh;
}

.container {
  text-align: center;
}

.header h1 {
  font-size: 48px;
  font-weight: normal;
  margin-bottom: 40px;
  letter-spacing: 0.05em;
}

.nav-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  gap: 60px;
}

.nav-item {
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  opacity: 0.6;
  transition: opacity 0.3s;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  position: relative;
}

.nav-item:hover,
.nav-item.active {
  opacity: 1;
}

.nav-item.active::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
}

.content {
  text-align: center;
}

.main-image {
  max-width: 600px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.title {
  font-size: 34px;
  font-weight: normal;
  margin-top: 20px;
}
</style> 