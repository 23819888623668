<template>
  <div id="app">
    <router-view></router-view>
    <chat-widget />

  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: #000000;
  color: #ffffff;
  font-family: helvetica-w01-light,helvetica-w02-light,sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  max-width: 980px;
  margin: 0 auto;
  padding: 40px 20px;
}
</style> 